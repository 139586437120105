module.exports = [{
      plugin: require('/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-33830458-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
