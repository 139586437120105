// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-single-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-page-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-about-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-portfolio-single-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/portfolio-single.js" /* webpackChunkName: "component---src-templates-portfolio-single-js" */),
  "component---src-templates-blog-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-tag-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-type-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */),
  "component---src-templates-author-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-pages-404-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-testimonials-js": () => import("/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/jenkins/.jenkins/workspace/gatsby-portfolio-lowgravity/.cache/data.json")

